import VueRouter, { createRouter, createWebHistory } from "vue-router";
import Session from "../modules/Session";
import { Common } from "../modules/Common";
import { useStore } from "../stores/useStore";


const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () => import("../layout/mainLayout"),
      children: [
        {
          path: "/:pathMatch(.*)*",
          name: "NotFound",
          component: () => import("../views/error_404"),
        },
        {
          path: "/home",
          name: "home",
          component: () => import("../views/home"),
        },
      ],
    },
  ],
});
// router.beforeEach((to, from, next) => {
//   if (Session.hasInspection()) {
//     if(to.path != "/inspection"){
//       next({
//         path: "/inspection",
//       });
//     }else{
//       next();
//     }
//   }else if (Session.hasSession()) {
//     //auth 거르고
//     if (to.meta.isAuth === undefined) {
//       next();
//     } else {
//       next({ name: "NotFound" });
//     }
//   } else {
//     if (to.meta.isAuth === true) {
//       next();
//     } else {
//       next({
//         path: "/login",
//       });
//     }
//   }
  
//   Common.windowScrollTop();
// });
export default router;
